/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:023bd445-b2f6-47fc-8f3d-275408b1b0c2",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_UhZ254p0d",
    "aws_user_pools_web_client_id": "7jp1adga03rbja4eh9deb72ug6",
    "oauth": {
        "domain": "picturedayprints01800a95-01800a95-dev.auth.us-east-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "http://localhost:8080/",
        "redirectSignOut": "http://localhost:8080/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [
        "GOOGLE",
        "AMAZON"
    ],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "picturedayprints7ce28c4318b54f5bb18861e285b7fb6165158-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://s6aptree45edpjobji2w6iviam.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_content_delivery_bucket": "picturedayprints-20220517043456-hostingbucket-dev",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "http://picturedayprints-20220517043456-hostingbucket-dev.s3-website-us-east-1.amazonaws.com"
};


export default awsmobile;
