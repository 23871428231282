<template>
  <div v-if="!user" class="home">
    <Login></Login>
  </div>
</template>

<script>
import Login from "@/components/Login.vue";
import { mapGetters } from "vuex";
export default {
  name: "HomeView",
  components: {
    Login,
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
    }),
  },
};
</script>
